.info {
    display: flex;
    align-self: start;
    background-color: white;
    border-radius: 16px;
    width: calc(100% - 40px);
    padding: 20px;
    gap: 20px;
}

.generalInfo {
    align-items: center;
    justify-content: start;
    flex-direction: row;
}

.generalInfo img{
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.education {
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.workInfo {
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 600px){
    .generalInfo {
        align-items: center;
        justify-content: start;
        flex-direction: column;
    }
}