.progress-loader {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.progress-loader {
    width: 150px;
    background: rgba(236, 236, 238, 0.253);
    height: 3px;
    border-radius: 7px;
}

.progress {
    content: '';
    width: 1px;
    height: 5px;
    border-radius: 7px;
    background: #153C6B;
    transition: 0.5s;
    animation: loading1274 5s ease;
}

@keyframes loading1274 {
    0% {
        width: 0%;
    }

    10% {
        width: 10%;
    }

    50% {
        width: 40%;
    }

    60% {
        width: 60%;
    }

    100% {
        width: 100%;
    }
}