.card {
    width: 300px;
    background-color: white;
    height: 675px;
}

.img {
    height: 400px;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
}

.text p {
    text-align: center;
}