.myChart {
    width: 100%;
}

.myChart2 {
    width: 100%;
}

@media screen and (max-width: 800px){
    .myChart {
        width: 600px;
    }
}

@media screen and (max-width: 600px){
    .myChart {
        width: 450px;
    }
}
