.block {
    width: calc(100% - 50px);
    padding: 25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block__inner {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 50px;
    flex-direction: column;
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
    .block {
        width: calc(100% - 20px);
        padding: 10px;
    }
}