.tabBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    font-weight: 500;
}
.tabHeader {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex-wrap: wrap;
    padding: 0 10px;
}
.tabHeader p {
    padding: 5px 20px;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}
.tabHeader p[active="true"] {
    border-top: 1px solid #9d9d9d;
    border-left: 1px solid #9d9d9d;
    border-right: 1px solid #9d9d9d;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
}
.tabHeader p[active="false"] {

}
.tabItem {
    width: calc(100% - 40px);
    border: 1px solid #9d9d9d;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 20px;
    background-color: white;
}
.tabItem[active="true"] {

}
.tabItem[active="false"] {
    display: none;
}

@media screen and (max-width: 1024px){
    .tabItem {
        width: calc(100% - 20px);
        padding: 10px;
    }
}