.header {
    background: #f0f3f5;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
}

.background_drawer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5000;
}

.wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1440px;
    width: 100%;
}

.header__left {
    display: flex;
    gap: 60px;
    align-items: center;
}

.header__right {
    display: flex;
    gap: 60px;
    align-items: center;
}

.header__svg {
    width: 80px;
}

.menu {
    cursor: pointer;
    display: none;
}

@media screen and (max-width: 1200px) {

    .header__left, .header__right, .header__svg {
        display: none;
    }

    .wrapper {
        justify-content: start;
    }

    .menu {
        padding-left: 40px;
        display: flex;
        align-items: center;
    }
    .menu__items {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }


}
