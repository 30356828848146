.text {
    font-weight: bold;
    font-size: 0.8rem;
}
.button {
    border: none;
    padding: 10px 35px;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
}