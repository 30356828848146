.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /*width: 300px;*/
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 20px 0;
}

.card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 0 10px;
}

.card__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 6px 10px;
    border-bottom: 1px solid lightgray;
}

.card__content img {
    height: 26px;
}

@media only screen and (max-width: 768px) {

}

