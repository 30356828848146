.sidebar {
    position: fixed;
    top: 90px;
    left: -300px;
    width: 275px;
    height: calc(100vh - 90px - 60px);
    padding: 25px 0;
    background: white;
    z-index: 9999;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transition: 0.5s;
    overflow-y: auto;
}
.sidebar[opened=true] {
    left: 0;
}

.sidebar::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}
.sidebar::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.8);
    border-radius: 10px;
}

.item {
    width: calc(100% - 40px - 5px);
    border-right: 5px solid white;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    min-height: calc(75px - 10px);
    height: calc(75px - 10px);
    cursor: pointer;
    transition: 0.3s;
}
.item:hover {
    transition: 0.3s;
    border-right: 5px solid #E5B600;
    background: #fff8dc;
}
.itemIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: relative;
}
.itemIcon img {
    max-width: 100%;
    max-height: 100%;
}

.itemRed {
    background-color: #E83151 !important;
    color: white !important;
    border-right: 5px solid #ffa4b1 !important;
}
.itemRed:hover {
    transition: 0.3s;
    background: #ff3d63 !important;
}