.card {
    width: 100%;
    min-height: 50px;
    max-width: 500px;
    padding: 20px;
    background-color: #0B7A75;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.card * {
    width: 100% !important;
    text-align: justify !important;
}