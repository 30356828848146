.button {
    border: none;
    padding: 10px 35px;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    display: block;
    text-align: center;
}