.mainBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.8rem;
}

.headerComponentName {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 243, 245);
    border-bottom: 1px solid #e8e8e8;
    padding: 10px;
    width: calc(100% - 20px);
}

.dataBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: calc(100% - 20px);
    gap: 10px;
}
.dataResult {
    font-weight: bolder;
}