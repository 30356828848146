.banner {

}
.bannerItem {
    width: 100%;
    height: 600px;
    padding: 50px 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.bannerItem p {
    color: white;
    font-weight: 700;
    text-align: left;
    font-size: 3rem;
    z-index: 10;
}
.bannerItem::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(124, 124, 124);
    background: linear-gradient(90deg, rgba(3, 3, 3, 0.7) 0%, rgba(54, 54, 54, 0.8) 50%, rgba(0, 0, 0, 0.7) 100%);
}

@media screen and (max-width:768px) {
    .bannerItem {
        height: 400px;
        padding: 25px;
    }
    .bannerItem p {
        font-size: 1.75rem;
    }
}
@media screen and (max-width:425px) {

    .bannerItem p {
        font-size: 1.5rem;
    }
}