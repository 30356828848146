.iconFlagBlock {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.iconFlagBlock img {
    width: 100%;
    height: 100%;
}