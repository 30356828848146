.message {
    background-color: white;
    padding: 10px;
    border-radius: 1.125rem;
    margin-bottom: 10px;
    max-width: 500px;
}

.sender {
    font-weight: bold;
    margin-bottom: 4px;
}

.content {
    margin-bottom: 4px;
    word-wrap: break-word;
}

.timestamp {
    font-size: 12px;
    color: #888;
}
