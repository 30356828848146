.blockLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(89, 89, 89, 0.2);
    z-index: 10000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.5);
    border-top-color: transparent;
    animation: rot1 1.2s linear infinite;
}

@keyframes rot1 {
    to {
        transform: rotate(360deg);
    }
}