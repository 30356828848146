.formBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.formField {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}
.formField label {
    padding: 0 15px ;
}
.formField input {
    font-size: 1rem;
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    padding: 10px 15px;
    width: calc(100% - 30px - 2px);
}
.formField textarea {
    font-size: 1rem;
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    padding: 10px 15px;
    width: calc(100% - 30px - 2px);
    resize: vertical;
    min-height: 200px;
}
.formField select {
    font-size: 1rem;
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    padding: 10px 15px;
    width: calc(100%);
}
.required {
    color: red;
}

.checkboxes {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
    flex-wrap: wrap;
    width: 100%;
}