.footer {
    background: #f0f3f5;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
}

.wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1440px;
    width: 100%;
}

.footer__left {
    display: flex;
    gap: 60px;
    align-items: center;
}

.footer__right {
    display: flex;
    gap: 60px;
    align-items: center;
}

.footer__svg {
    width: 100px;
}

@media only screen and (max-width: 600px) {
    .footer__left {
        gap: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .footer {
        height: 160px;
        padding: 10px 0;
    }
    .wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
}

