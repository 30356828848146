.courseHeader {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    background: white;
    width: calc(100% - 200px);
    border-radius: 25px;
    padding: 20px 100px;
    gap: 50px;
}

.courseHeaderTextDiv {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column
}

.courseHeader img {

}

.image {
    border-radius: 50%;
    overflow: hidden;
    width: 300px;
    height: 300px;
}

.image img {
    width: 100%;

}



.courseButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: white;
    width: calc(100% - 100px);
    border-radius: 25px;
    padding: 30px 50px;
    gap: 50px;
}

@media only screen and (max-width: 1024px) {
    .courseButtons {
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media only screen and (max-width: 900px) {

    .courseHeader {
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }

    .courseHeaderTextDiv {
        align-items: center;
    }

    .courseHeaderTextDiv>* {
        text-align: center;
    }
}